.App {
  background-color: "#f5f5f5";
  align-items: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.card-form {
  background-color: red;
}

.App-header {
  background-color: #555555;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  /* padding-bottom: 56.2%; */
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.container iframe {
  overflow: hidden;
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}

/* Loading GoodLeap spinner */
.loading-smiley-container {
  position: fixed;
  z-index: 1000;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-smiley-container:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-smiley {
  z-index: 9999;
  height: 50px;
  position: relative;
  right: 33px;
  animation: load 1.3s ease infinite;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-30deg);
  }
  70% {
    transform: rotate(390deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Amplify themes */
.amplify-button[data-variation='primary'] {
  background: linear-gradient(
    to right,
    var(--amplify-colors-green-80),
    var(--amplify-colors-orange-60)
  );
}

/* clipboard icon */
.gg-clipboard {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px
}
.gg-clipboard::after,
.gg-clipboard::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 2px;
  width: 10px;
  left: 2px
}
.gg-clipboard::before {
  border: 2px solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  top: -2px;
  height: 6px
}
.gg-clipboard::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 -4px 0 0;
  bottom: 2px
}

.gg-check-o-green {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
  color: rgb(0, 170, 0);
}

.gg-check-o-green::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
  color: rgb(0, 170, 0);
}

/* check icon <i class="gg-check-r"></i> */
.gg-check-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs,1));
  border-radius: 4px
 }

 .gg-check-r::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg)
 }

 /* <i class="gg-close-o-red"></i> */
 .gg-close-o-red {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 22px;
   height: 22px;
   border: 2px solid;
   border-radius: 40px;
   color: rgb(212, 0, 0);
 }

 .gg-close-o-red::after,
 .gg-close-o-red::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 12px;
   height: 2px;
   background: currentColor;
   transform: rotate(45deg);
   border-radius: 5px;
   top: 8px;
   left: 3px;
  color: rgb(212, 0, 0);
 }

 .gg-close-o-red::after {
   transform: rotate(-45deg)
 }

/*check icon <i class="gg-close-r"></i> */
.gg-close-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px
 }

 .gg-close-r::after,
 .gg-close-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px
 }

 .gg-close-r::after {
  transform: rotate(-45deg)
 }

 /* Amplify Loader */
 .amplify-loader {
  --amplify-components-loader-linear-stroke-width: 10px;
  --amplify-components-loader-linear-animation-duration: 2s;
 }

 .amplify-card {
  --amplify-components-card-padding: 8px;
 }

.amplify-label {
  font-size: 13px
 }

 .amplify-selectfield {
  --amplify-components-selectfield-font-size: .7rem
}

.ampolify-table--small {
  --amplify-internal-table-th-font-size: .0875rem
}

.amplify-select-small {
  margin-bottom: 20px !important;
  padding: 6px;
  font-size: 16px !important;
}

.amplify-select__icon-wrapper {
  top: 35% !important
}

.amplify-divider--horizontal {
  width: 95% !important;
  align-items: center;
  text-align: center;
}

.amplify-divider--label {
  font-weight: 500;
  --amplify-components-divider-label-font-size: 1.45rem;
  --amplify-components-divider-label-background-color: #FCF5EA;
  --amplify-components-divider-label-color: "#FF8300";
  --amplify-components-divider-label-padding-inline: 1rem;
}

 /* Media queries */

 @media only screen and (max-width : 480px) {
  .card-form {
    width: 100% !important;
  }
  .logo {
    width: 300px !important;
  }
  .loading-smiley-container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  .heading-box {
    width: 100% !important;
  }
}